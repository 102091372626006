.vague-large {
    top: 80%;
    width: 100%;
    position: absolute;
}

.home-intro {
    width: 100%;
    background: #333533;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    height: 700px;
}

.home-intro-content {
    width: 40%;
}

.home-intro-test {
    position: absolute;
    top: 700px;
    width: 100%;
}

.home-intro-content h1 {
    font-size: 100px;
    background: linear-gradient(90deg, #FB851E -2.1%, #E8BC31 102.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.home-intro-content-textfirst {
    font-size: 43px;
    color: #ffffff;
    line-height: 30px;
    margin: 0;
    background: linear-gradient(90deg, #FB851E -2.1%, #E8BC31 102.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.home-intro-content-textsecond {
    font-size: 25px;
    color: #ffffff;
    margin-top: 80px;
    text-align: justify;
}

.home-video {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.home-video-play {
    width: 10%;
    position: absolute;
    top: 1950px;
    left: 920px;
    cursor: pointer;
}

.home-video-vague {
    width: 100%;
}

.home-users {
    width: 100%;
    height: 1100px;
    position: relative;
}

.home-users-vague {
    width: 100%;
}

.home-users-vague2 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.home-users-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home-users-numbers {
    width: 450px;
    height: 410px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home-streamers-numbers {
    width: 550px;
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home-streamers-numbers > p > span,
.home-users-numbers > p > span{
    font-size: 80px;
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.home-streamers-numbers p,
.home-users-numbers p {
    font-size: 40px;
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.home-users-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}

.home-users-vague5 {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media screen and (max-width: 1500px) {
    .vague-large {
        top: 85%;
        width: 100%;
        position: absolute;
    }

    .home-intro-content h1 {
        font-size: 75px;
        background: linear-gradient(90deg, #FB851E -2.1%, #E8BC31 102.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 0;
    }

    .home-intro-content-textfirst {
        font-size: 33px;
        color: #ffffff;
        line-height: 40px;
        margin: 0;
        background: linear-gradient(90deg, #FB851E -2.1%, #E8BC31 102.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .home-intro-content-textsecond {
        font-size: 25px;
        color: #ffffff;
        margin-top: 80px;
        text-align: justify;
    }

    .home-video-play {
        width: 10%;
        position: absolute;
        top: 1750px;
        left: 700px;
        cursor: pointer;
    }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
    .home-video-play {
        width: 10%;
        position: absolute;
        top: 2400px;
        left: 1200px;
        cursor: pointer;
    }

    .home-users {
        height: 1350px;
    }

    .home-users-buttons > button {
        width: 300px;
    }
}


