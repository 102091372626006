.home-streamer {
    height: 100vh;
    width: 100%;
    margin: 0;
}

.streamer-background {
    background: #333533;
}

.streamer-vague1 {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.home-first-streamer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 0;
}

.home-first-streamer-content {
    width: 40%;
    justify-content: center;
    text-align: center;
}

.home-first-streamer-content h1 {
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 120px;
    height: 170px;
}

.home-first-streamer-content span {
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 55px;
    line-height: 2px;
    text-transform: uppercase;
    font-weight: bold;
}

.home-first-streamer-content p {
    margin: 20px;
    color: #FFFFFF;
    font-size: 30px;
    text-align: center;
}

.home-first-streamer-img {
    width: 45%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-list-streamer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vague-streamer {
    margin: 0;
}

.home-list-streamer-flex {
    margin-bottom: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px;
}

.video-stream {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-stream > h2 {
    margin-bottom: 50px;
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 55px;
    font-weight: bold;
}

.video-stream > button {
    margin-top: 50px;
}
