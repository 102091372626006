.btn-global {
    border: none;
    background: linear-gradient(89.73deg, #FB851E 0.22%, #E8BC31 99.76%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    color: #ffffff;
    padding: 15px;
    width: 180px;
    font-size: 25px;
    cursor: pointer;
}