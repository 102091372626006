*,::before,::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
}

a {
    color: #FFFFFF;
    text-decoration: none;
}

