.propos-intro {
    width: 100%;
    position: relative;
    display: flex;
    height: 870px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.propos-illegal {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 400px;
    position: relative;
}

.propros-intro-18 {
    margin-top: 200px;
    width: 100%;
    font-weight: 900;
    text-align: center;
    font-size: 50px;
}

.propros-intro-18 > p {
    color: #202020;
    -webkit-text-stroke: 1px white; /* Ajoutez cette ligne sans "-color" */
    text-stroke: 1px white; /* Pour une compatibilité plus large */
}

.propos-intro-logo {
    display: flex;
    justify-content: right;
    align-items: end;
    margin-right: 30px;
}

.logo18 {
    width: 5%;
    margin-top: 30px;
}

.propos-intro-img {
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
}

.propos-intro h1 {
    font-size: 100px;
    background: linear-gradient(90deg, #FB851E -2.1%, #E8BC31 102.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.propos-intro-content {
    width: 50%;
}

.propos-intro-rectangle {
    height: 370px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.propos-intro-rectangle p {
    font-size: 25px;
    text-align: center;
    width: 70%;
    margin-top: 70px;
}

.propos-intro-rectangle h1 {
    font-size: 100px;
    text-align: center;
    width: 50%;
}

.propos-intro-rectangle img {
    width: 100%;
    position: absolute;
    top: 81%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*
    PROPOS EMS
 */

.propos-ems {
    width: 100%;
    position: relative;
    height: 950px;
}

.propos-ems-card-content {
    padding: 80px;
    font-size: 21px;
    color: #202020;
    text-align: left;
}

.propos-ems-card-content > h2 {
    font-size: 50px;
}

.propos-ems-intro {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 400px 450px;
}

.propos-ems-intro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-align: center;
    color: #202020;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    -webkit-text-stroke: 1px white; /* Ajoutez cette ligne sans "-color" */
    text-stroke: 1px white; /* Pour une compatibilité plus large */
}

.propos-ems-flex {
    display: flex;
    justify-content: space-between;
}

.card-ems {
    height: 100%;
}

.propos-ems-vague {
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: 0;
}

/*
    PROPOS LCPD
 */

#cardlcpd {
    height: 410px;
}

.propos-lcpd {
    height: 1340px;
    position: relative;
}

.propos-orga {
    width: 100%;
    position: relative;
}


.propos-lcpd-intro {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: 200px 1380px;
}

.propos-lcpd-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1350px;
}

.propos-lcpd-card-content {
    padding: 100px;
    font-size: 21px;
    color: #202020;
    text-align: left;
}

.propos-lcpd-card-content > h2 {
    font-size: 50px;
}

.propos-lcpd-card-content h2{
    text-align: center;
}

.card-lcpd {
    height: 600px;
    width: 30%;
}

.propos-lcpd-vague {
    position: absolute;
    bottom: 0;
}

/*
    PROPOS GOUV
 */

.propos-gouv {
    height: 1250px;
    position: relative;
    display: grid;
    grid-template-rows: 200px 1fr;
}

.propos-gouv-intro {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: 100px 1600px;
}

.propos-gouv-card-content {
    padding: 100px;
    font-size: 21px;
    color: #202020;
    text-align: left;
}

.propos-gouv-vague {
    position: absolute;
    bottom: 0;
}

#cardgouv {
    height: 35%;
}


/*
    PROPOS INTRO ILEGAL
 */

.propos-intro-illegal {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.propos-intro-illegal p {
    font-size: 22px;
    text-align: center;
    width: 50%;
}

/*
    PROPOS ORGA
 */

.propos-orga {
    width: 100%;
    position: relative;
    height: 1250px;
}

.propos-orga-intro {
    height: 1200px;
    width: 100%;
    display: grid;
    grid-template-rows: 380px 820px;
}

.propos-orga-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.card-orga {
    height: 50%;
    width: 30%;
}

.propos-orga-card-content {
    padding: 70px;
    font-size: 23px;
    color: #202020;
    text-align: left;
}

.propos-gouv-card-content > h2 {
    font-size: 50px;
}

#cardillegal {
    height: 45%;
}

@media screen and (max-width: 1920px) {
    .propos-orga {
        height: 1220px;
    }

    .propos-lcpd {
        height: 1280px;
        position: relative;
    }


    #cardlcpd {
        height: 430px;
    }

    .propos-lcpd-card-content {
        padding: 75px;
        font-size: 21px;
        color: #202020;
        text-align: left;
    }

    #cardillegal {
        height: 40%;
    }

    .propos-orga {
        width: 100%;
        position: relative;
        height: 1160px;
    }

    .propos-orga-card-content {
        padding: 50px;
        font-size: 20px;
        color: #202020;
        text-align: left;
    }

    .card-orga {
        height: 45%;
        width: 30%;
    }

    #cardgouv {
        height: 35%;
    }

    .propos-gouv-card-content {
        padding: 70px;
    }

    .propos-gouv {
        height: 1230px;
        position: relative;
        display: grid;
        grid-template-rows: 200px 1fr;
    }

    .propos-ems {
        width: 100%;
        position: relative;
        height: 950px;
    }
}