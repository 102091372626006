.btn-outline {
    background-color: transparent;
    border: 4px solid rgba(232, 188, 49, 1); /* Utilisation de l'alpha RGBA */
    padding: 15px;
    border-radius: 33px;
    width: 200px;
    font-size: 22px;
    margin-left: 50px;
    color: #ffffff;
    cursor : pointer;
}