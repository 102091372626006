.footer {
    width: 100%;
    height: 400px;
    background: #333533;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    margin-top: 50px;
}

.footer-discord {
    margin-top: 50px;
}

.footer-discord button {
    position: absolute;
    margin: 30px 250px;
}

.footer-content h2 {
    background: linear-gradient(90deg, #FB851E -2.84%, #E8BC31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 70px;
    margin: 0 100px;
}

.footer-nav {
    display: flex;
    text-align: justify;
    font-size: 20px;
    justify-content: space-around;
    align-items: center;
}

.footer-nav a {
    margin-top: 30px;
}

.footer-copyright {
    background: #333533;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    flex-direction: column;
}