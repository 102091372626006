.header-navbar {
    width: 100%;
}

.navbar {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 1fr;
    height: 130px;
    background: #202020;
    position: fixed;
    width: 100%;
    z-index: 2;
}

.navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.navbar-logo > img {
    width: 35%;
    border-radius: 20px;
}

.navbar-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.navbar-items a  {
    margin-right: 120px;
    font-size: 23px;
    text-decoration-color: rgba(0, 0, 0, 0);
    padding: 10px 20px;
    border-radius: 33px;
}

.navbar-items a:hover {
    background-color: transparent;
    border: 3px solid rgba(232, 188, 49, 1); /* Utilisation de l'alpha RGBA */
}

.navbar-vague img {
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: fixed;
    margin-top: 130px;
    z-index: 1;
}

.hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: none;
}

.hamburger span {
    position: absolute;
    height: 3px;
    width: 100%;
    background: #ffffff;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) {
    top: 0px;
}

.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
    top: 8px;
}

.hamburger span:nth-child(4) {
    top: 16px;
}

.hamburger.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
}

.hamburger.open span:nth-child(2) {
    transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
}


@media screen and (max-width: 750px) {
    .navbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        max-height: none;
    }

    .navbar-items {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .navbar-items a {
        margin-right: 0;
        display: none;
    }

    .navbar-vague img {
        width: 20%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        position: fixed;
        max-height: none;
    }

    .navbar-logo {
        margin-top: 0;
    }

    .hamburger {
        display: block;
    }

    .mobile-navbar {
        height: 200px;
    }

    .mobile-vague img {
        margin-top: 200px;
    }
}

.dropbtn {
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

/* Style du contenu du menu déroulant (caché par défaut) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #202020;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 33px;
    margin-top: 10px;
    max-width: 220px;
}

.dropdown-content a {
    color: #FFFFFF;
    padding: 20px 20px;
    text-decoration: none;
    display: block;
    font-size: 22px;
}

.dropdown-content a:hover {
    background: none;
}

/* Style des liens du menu déroulant */
.dropdown-link {
    color: #FFFFFF;
    font-size: 19px;
    text-decoration: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 20px;
}

/* Afficher le contenu du menu déroulant lorsque le bouton est survolé ou cliqué */
.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (max-width: 1920px) {
    .navbar-items a {
        font-size: 22px;
        margin-right: 80px;
    }
}